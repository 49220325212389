import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider, RequireAuth } from './auth/AuthContext'
import './scss/style.scss'
import { PagoExitosoPage } from './views/public/MensajePages'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/auth/login/LoginPage'))
const ActivateAccountPage = React.lazy(() =>
  import('./views/auth/activate-account/ActivateAccountPage'),
)
const ChangePasswordPage = React.lazy(() =>
  import('./views/auth/change-password/ChangePasswordPage'),
)
const RecoverPasswordPage = React.lazy(() =>
  import('./views/auth/recover-password/RecoverPasswordPage'),
)
const PagarCuotaPage = React.lazy(() => import('./views/public/PagarCuotaPage'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/pagar-cuotas" name="Pagar cuotas" element={<PagarCuotaPage />} />
              <Route
                exact
                path="/pagar-cuotas/pago-exitoso"
                name="Pago exitoso"
                element={<PagoExitosoPage />}
              />
              <Route exact path="/login" name="Iniciar Sesión" element={<Login />} />
              <Route
                exact
                path="/activate-account"
                name="Activar cuenta"
                element={<ActivateAccountPage />}
              />
              <Route
                exact
                path="/change-password"
                name="Cambiar contraseña"
                element={<ChangePasswordPage />}
              />
              <Route
                exact
                path="/recover-password"
                name="Recuperar contraseña"
                element={<RecoverPasswordPage />}
              />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route
                path="*"
                name="Inicio"
                element={
                  <RequireAuth>
                    <DefaultLayout />
                  </RequireAuth>
                }
              />
            </Routes>
          </Suspense>
        </HashRouter>
      </AuthProvider>
    )
  }
}

export default App
