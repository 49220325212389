import React from 'react'
import { useNavigate } from 'react-router-dom'

import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow, CButton } from '@coreui/react'
import { HeaderComponent } from './HeaderComponent'

export const MensajeComponent = ({ title, buttonHandler }) => {
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <HeaderComponent title={title} />
                  <CRow>
                    <CCol>
                      <div className="d-grid gap-2">
                        <CButton color="primary" className="px-4" onClick={buttonHandler}>
                          Volver
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export const PagoExitosoPage = () => {
  const navigate = useNavigate()

  const buttonHandler = () => {
    navigate('/pagar-cuotas')
  }

  return <MensajeComponent title="Pago exitoso" buttonHandler={buttonHandler} />
}
