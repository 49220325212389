import React from 'react'
import logo from '../../assets/brand/logo100x100.png'
import { CImage } from '@coreui/react'

export const HeaderComponent = ({ title }) => {
  return (
    <>
      <div className="text-center mb-4">
        <CImage src={logo} height={100} />
      </div>
      <p className="text-medium-emphasis text-center">
        <strong>Asociación Canales de Maipo</strong>
      </p>

      <h3 className="text-center mb-5">{title}</h3>
    </>
  )
}
