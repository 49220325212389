const dev = {
  API_ENDPOINT_URL: 'https://acm-api-scl.apdemo.net',
  FINTOC_PUBLIC_KEY: 'pk_test_yVzXU93msLt6ALL3FGrGUJ-Ak9nyNqMeTW4DzRiMx7A',
}

const prod = {
  API_ENDPOINT_URL: 'https://api-scl.asociacioncanalesdemaipo.cl',
  FINTOC_PUBLIC_KEY: 'pk_live_e2yaKGJgxuvDWz-j9qR5q8yRa5b-sC-gkeyEK8g1eso',
}

const test = {
  API_ENDPOINT_URL: 'https://api-scl.acm.test',
  FINTOC_PUBLIC_KEY: 'pk_test_yVzXU93msLt6ALL3FGrGUJ-Ak9nyNqMeTW4DzRiMx7A',
}

const getEnv = () => {
  console.log('Mensaje')
  console.log(process.env.NODE_ENV)
  switch (process.env.NODE_ENV) {
    case 'development':
      return dev
    case 'production':
      return prod
    case 'test':
      return test
    default:
      break
  }
}

export const ENV = getEnv()
